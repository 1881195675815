.course-detail-wrap {
  color: var(--color-text);

  .course-info {
    max-width: 800px;

    .button {
      font-size: 18px;
      line-height: 24px;
      padding: 23px 35px;
    }
  }

  .course-name {
    color: white;
    font-size: 64px;
    margin-bottom: 24px;
    margin-top: 0;
    font-weight: 700;
    line-height: 110%;

    &:before {
      content: attr(data-firstWord);
      display: block;
      color: var(--color-text-heading-first);
    }
  }

  @media only screen and (max-width: 576px) {
    .course-name {
      font-size: 45px;
    }

    .course-info {
      .button {
        width: 100%;
        margin: 0;
      }
    }
  }

  .course-description {
    color: var(--color-text);
    opacity: .8;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.36px;
  }

  .banner-image {
    width: 240px;
    height: 240px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }

  .description-items-wrap {
    display: flex;
    gap: 8px;
    margin-bottom: 48px;
    margin-top: 24px;

    &.w-100{
      width: 100%;
    }



    .desc-item {
      border-radius: 12px;
      border: 1px solid rgba(255, 255, 255, 0.16);
      background: rgba(0, 0, 0, 0.72);
      box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.12) inset;
      backdrop-filter: blur(18px);
      -webkit-backdrop-filter: blur(18px);
      padding: 15px 24px;
      box-sizing: border-box;
      color: var(--color-text);
      min-width: 180px;

      &.desc-item-flexible {
        width: 25%;
        min-width: unset;
      }

      .desc-item-title {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
        opacity: .6;
        margin-bottom: 6px;
      }

      .desc-item-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .description-items-wrap {
      flex-wrap: wrap;

      .desc-item {
        width: calc(50% - 4px);

        &.desc-item-flexible {
          width: calc(50% - 4px);
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .description-items-wrap {
      .desc-item {
        width: 100%;
        
        &.desc-item-flexible {
          width: 100%;
        }
      }
    }
  }

  .section-course-details {
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 992px) {
    .section-course-details {
      padding-bottom: 60px;
    }
  }

  .course-details-tab-area {
    margin-top: 20px;
    width: 100%;
    padding: 0px 48px 48px 48px;
    border-radius: 36px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    margin-bottom: 0;

    .tab-btn-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding-left: 56px;
      position: relative;

      .tab-btn {
        padding: 36px;
        cursor: pointer;
        color: var(--color-text);
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.36px;
        opacity: 0.8;
        position: relative;

        &.active {
          font-weight: 600;
        }

        &.active, &:hover {
          color: var(--color-text-accent);
          opacity: 1;
          border-bottom: 2px solid #FDFFA6;
          background: rgba(253, 255, 166, 0.08);
        }
      }
    }

    .label-sale {
      position: absolute;
      right: 14px;
      top: -16px;
      z-index: -1;

      svg {
        width: 100px;
        height: 100px;

        path {
          fill: var(--label-closed-color);
        }
      }
    }

    .label-sale {
      position: relative;
      display: inline-block;
      background: var(--label-closed-color);
      color: white;
      height: 2.5rem;
      width: 2.5rem;
      text-align: center;
      vertical-align: middle;
      line-height: 2.5rem;
      margin: 27px 0 0;
      transform: rotate(-20deg);
      animation: beat 1s ease infinite alternate;
      font-weight: 700;

      &:before,
      &:after {
        content:"";
        position: absolute;
        background: inherit;
        height: inherit;
        width: inherit;
        top: 0;
        left: 0;
        z-index: -1;
        transform: rotate(30deg);
      }
      &:after {
        transform: rotate(60deg);
      }
    }
    
    @keyframes beat {
      from {	transform: rotate(-20deg) scale(1); }
      to {	transform: rotate(-20deg) scale(1.1); }
    }

    .tab-content-wrap {
      display: flex;
      justify-content: space-between;
      padding: 44px 56px 56px;
      border-radius: 16px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      background: rgba(0, 0, 0, 0.72);
      box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.08) inset;
      backdrop-filter: blur(18px);
      -webkit-backdrop-filter: blur(18px);

      .count-place {
        display: flex;
        flex-direction: column;

        .number {
          color: #FDFFA6;
          text-align: center;
          font-size: 96px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: -5.76px;
          font-family: Unbounded;
          margin-bottom: 4px;
        }

        .text {
          color: var(--color-text);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 25.2px */
          letter-spacing: -0.36px;
          opacity: .6;
          text-align: center;
        }
      }

      .courses-details-tab-panel {
        width: calc(100% - 132px - 65px);

        .tab-content {
          color: var(--color-text);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 180%;
          letter-spacing: -0.32px;
          opacity: 0.92;
          width: 100%;

          .tab-pane {
            display: none;

            &.active {
              display: block;
            }
          }
        }

        .rating-title {
          font-size: 20px;
          margin: 0 0 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .course-details-tab-area {
      padding: 0 24px 48px;

      .tab-btn-list {
        padding-left: 36px;

        .tab-btn {
          padding: 26px;
        }

        .label-sale {
          position: absolute;
          top: -16px;
          right: 7px;
          width: 35px;
          height: 35px;
          font-size: 15px;
          z-index: 1;
        }
      }

      .tab-content-wrap {
        flex-direction: column;
        padding: 36px 30px 48px;

        .count-place {
          margin-bottom: 24px;
          padding-bottom: 14px;
          align-items: flex-end;
          flex-direction: row;
          border-bottom: 1px solid rgba(255, 255, 255, 0.16);

          .number {
            font-size: 50px;
          }

          .text {
            margin-bottom: 11px;
            margin-left: 10px;
          }
        }

        .courses-details-tab-panel {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .course-details-tab-area {
      padding: 0;
      position: relative;
      overflow: hidden;

      .tab-btn-list {
        padding-left: 0;
        flex-wrap: wrap;

        .tab-btn {
          padding: 12px;
          width: fit-content;
          line-height: 120%;
          font-size: 14px;
          width: 50%;
          text-align: center;
          justify-content: center;
          border-radius: 0;

          &.active {
            border: none;
          }
        }

        .label-sale {
          right: 18px;
          top: 30px;
        }
      }

      .tab-content-wrap {
        padding: 24px 12px 28px;
        border: none;

        .count-place {
          margin-bottom: 16px;
          padding-bottom: 8px;

          .number {
            font-size: 40px;
          }

          .text {
            font-size: 14px;
          }
        }

        .courses-details-tab-panel {
          .tab-content {
            font-size: 14px;
            line-height: 160%;
          }
        }
      }
    }
  }

  .lessons-wrap {
    display: flex;
    column-gap: 50px;
   
    ul {
      margin: 0;
    }

    .week-title{
      color: #fdffa6;
      font-size: 18px;
      margin-left: -20px;

      &:not(:first-child){
        margin-top: 20px;
      }
    }

    .lessons-block-half {
      list-style: none;
      padding: 0;
      width: 50%;
    }

    li {
      padding: 4px 0;
      
      &::marker {
        color: var(--color-text-accent);
        
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .lessons-wrap {
      ul {
        margin-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .lessons-wrap {
      ul {
        padding-left: 20px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .lessons-wrap {
      flex-direction: column;

      .lessons-block-half {
        width: 100%;
        margin: 0;
      }
    }
  }

  .comments-reply-area {
    .extra-price-info{
      backdrop-filter: blur(12px);
      -webkit-backdrop-filter: blur(12px);
      background: linear-gradient(180deg,hsla(0,0%,100%,.04),hsla(0,0%,100%,.02));
      border: 1px solid hsla(0,0%,100%,.08);
      border-radius: 12px;
      padding: 6px 4px 4px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: normal;
    }

    .course-price-info {
      display: flex;
      column-gap: 80px;
      justify-content: space-between;

      .course-current-price-wrapper {
        .price-title {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.36px;
          opacity: 0.6;
          margin-bottom: 8px;
        }
      }

      .course-current-price {
        color: var(--color-text-accent);
        text-align: center;
        font-family: var(--font-family-heading);
        font-size: 140px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: -9.6px;
        width: fit-content;
      }
      .price-uah {
        opacity: .68;
        margin-top: -6px;
        margin-bottom: 12px;
      }

      .course-sale {
        .price-title {
          color: #FFF;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          letter-spacing: -0.36px;
          opacity: 0.6;
          margin-bottom: 8px;
        }

        .price-old {
          color: #FFF;
          font-family: var(--font-family-heading);
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.48px;
          padding: 8px 20px;
          border-radius: 100px;
          background: #DA0D0D;
          margin-bottom: 8px;
          width: fit-content;
        }

        .price-sale-percent {
          color: #FFF;
          font-family: var(--font-family-heading);
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.48px;
          border-radius: 100px;
          border: 4px solid #DA0D0D;
          padding: 4px 16px;
          width: fit-content;

          &.price-promo-percent {
            margin-top: 8px;
            border-color: var(--label-in-progress-color);
            display: flex;
            align-items: center;

            svg {
              width: 25px;
              height: 25px;
              fill: #fff;
              margin-right: 12px;
              stroke: #fff;
            }
          }
        }
      }
    }

    .box-field {

      @media only screen and (max-width: 576px){
        input[name="second_name"] {
          display: none;
        }
      }
      backdrop-filter: blur(18px);
      -webkit-backdrop-filter: blur(18px);
      background: rgba(0,0,0,.72);
      border: 1px solid hsla(0,0%,100%,.16);
      border-radius: 12px;
      box-sizing: border-box;
      color: hsla(0,0%,100%,.6);
      font-family: var(--font-family-base);
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -.28px;
      line-height: normal;
      outline: #fff;
      padding: 15px 23px;
      width: 100%;
    }

    .course-promocode-info {
      margin-top: 36px;
      margin-bottom: 14px;
      padding-top: 36px;
      border-top: 1px solid hsla(0,0%,100%,.16);

      .promo-title {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -0.4px;
        margin-bottom: 14px;
        display: flex;
        align-items: center;
        column-gap: 3px;
      }

      .promo-form {
        display: flex;
        column-gap: 12px;

        .button {
          width: fit-content;
          min-width: fit-content;
        }

        #promo_result {
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .comments-reply-area {
      .course-price-info {
        .course-current-price {
          font-size: 85px;
        }
      }

      .extra-price-info {
        margin-top: 20px;
        padding: 6px 10px 4px;
        line-height: 2;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .comments-reply-area {
      .course-price-info {
        flex-direction: column;
        row-gap: 15px;

        .course-current-price-wrapper {
          .price-title {
            font-size: 16px;
          }
        }

        .course-current-price {
          font-size: 70px;
          letter-spacing: -3.6px;
        }

        .course-sale {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          row-gap: 8px;
          column-gap: 10px;

          .price-title {
            font-size: 16px;
            width: 100%;
            margin-bottom: 0;
          }

          .price-old, .price-sale-percent {
            font-size: 18px;
            margin: 0;
          }

          .price-sale-percent {
            border: 3px solid #da0d0d;
            padding: 5px 16px;
          }
        }
      }

      .course-promocode-info {
        margin-top: 24px;
        padding-top: 20px;

        .promo-title {
          flex-direction: column;
          align-items: flex-start;
        }

        .promo-form {
          flex-direction: column;
          row-gap: 20px;

          .button {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  .promo-label {
    background: var(--label-in-progress-color);
    border-radius: 4px;
    color: var(--color-text-label);
    font-family: var(--font-family-heading);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: .4px;
    line-height: normal;
    padding: 4px 20px 4px 8px;
    text-transform: uppercase;
    width: fit-content;
    display: inline-flex;
    align-items: center;
    margin-left: 20px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }
}

.pseido-elem {
  position: absolute;
  width: fit-content;
  height: fit-content;
}

.courses-details-cont {
  * {
    color: white!important;
  }
}
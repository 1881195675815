.section-reviews {
  background-color: var(--background-secondary);
  padding-top: 48px;
  padding-bottom: 48px;

  .section-header {
      margin-bottom: 36px;
  }

  .banner-title {
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    text-align: center;

    span, .grey {
      display: block;
    }
  }

  .view-all-btn {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 992px) {
  .section-reviews {
    padding-top: 24px;
    padding-bottom: 24px;
    margin-top: -1px;
  }
}

#review_wrap {
  max-height: 470px;
  overflow-y: hidden;
  position: relative;
  margin: -10px;

  &:after {
    content: '';
    position: absolute;
    height: 345px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
  }

}

.text-review-item {
  margin-bottom: 20px;
  padding: 24px;
  box-sizing: border-box;
  width: calc(25% - 20px);
  float: left;
  border-radius: 12px;
  background: #1A1A1A;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  margin: 10px;


  .text-reviews-author {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .avatar {
      width: 32px;
      height: 32px;
      margin-right: 12px;

      .img {
        max-width: 100%;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .default-avatar {
        display: flex;
        background: var(--color-text);
        text-align: center;
        align-items: center;
        justify-content: center;
        align-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .name {
      color: var(--color-text);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
    }

  }

  .text-reviews-text {
    color: var(--color-text);
    opacity: .8;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.28px;
  }
}

.reviews-company {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  .reviews-item {
    width: calc(50% - 10px);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: #0C0C0C;
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);

    .reviews-img {
      width: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .reviews-content {
      padding: 24px;

      .review-title {
        color: var(--color-text);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.48px;
        margin-bottom: 12px;

        .accent {
          display: block;
          color: #FDFFA6;
        }
      }

      .review-text {
        color: var(--color-text);
        opacity: 0.8;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        letter-spacing: -0.28px;
      }
    }
  }
}

.video-reviews {
  padding-top: 24px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 992px) {
  .reviews-company {
    flex-wrap: wrap;
    gap: 20px;

    .reviews-item {
      width: 100%;

      .reviews-image {
        height: 114px;

        .reviews-img {
          height: 100%;
          object-fit: cover;
          object-position: right;
        }
      }
    }
  }

  #review_wrap {
    max-height: 668px;

    &:after {
      height: 200px;
      bottom: -2px;
    }
  }

  .text-review-item {
    width: calc(33.33% - 20px);
  }
}

@media only screen and (max-width: 576px) {
  #review_wrap {
    max-height: 448px;
  }

  .text-review-item {
    width: calc(100% - 20px);
  }

  .video-reviews {
    .section-header {
      .banner-title {
        font-size: 25px;
      }
    }
  }
}



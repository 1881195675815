
.form-wrap {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: rgba(0, 0, 0, 0.72);
  box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.08) inset;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  padding: 44px 32px;
  box-sizing: border-box;
  border-radius: 16px;

  .iti__selected-flag {
    //background: white !important;
  }

  .text-wrap {
    padding-bottom: 36px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    margin-bottom: 36px;
  }

  h3, .h3 {
    margin-bottom: 12px;
    font-size: 36px;
    font-weight: 600;
    line-height: 120%;
  }

  .text {
    max-width: 340px;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.28px;
    opacity: 0.8;
    color: var(--color-text);
  }

}

.two-fields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 8px;

  .input-box {
    width: 49%;
  }
}



.input-box {
  margin-bottom: 16px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 12px;
    textarea {
      height: 50px;
    }
  }

  .iti {
    width: 100%;
  }

  .iti__country-list {
    background: black;
  }

  .box-field {
    font-family: var(--font-family-base);
    width: 100%;
    padding: 15px 23px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.6);
    box-sizing: border-box;
    outline: white;
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.16);
    background: rgba(0, 0, 0, 0.72);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    line-height: normal;
    letter-spacing: -0.28px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }


    &.error {
      border: 1px solid #ca3030d1;
      background: #1c1119;
    }
  }

  .field-select {
    cursor: pointer;
    appearance: none;
  }

  &.input-box-select {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 24px;
      top: 50%;
      width: 15px;
      height: 15px;
      transform: translateY(-50%);
      opacity: 0.6;
      user-select: none;
      pointer-events: none;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg fill='%23ffffff' height='200px' width='200px' version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 330 330' xml:space='preserve' stroke='%23ffffff' transform='rotate(90)'%3E%3Cg id='SVGRepo_bgCarrier' stroke-width='0'%3E%3C/g%3E%3Cg id='SVGRepo_tracerCarrier' stroke-linecap='round' stroke-linejoin='round'%3E%3C/g%3E%3Cg id='SVGRepo_iconCarrier'%3E%3Cpath id='XMLID_222_' d='M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    }
  }
}


.thanks-text,
.error-text {
  display: none;
  text-align: center;
  color: var(--color-text);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.36px;
  opacity: 0.8;
}

.error-text {
  color: #a31515;
  font-weight: 600;
}

.show {
  display: block;
}


.inline-field {
  position: relative;

  .btn {
    position: absolute;
    right: 0;
    top: 1px;
    height: calc(100% - 2px);
    border-radius: 12px;
    outline: none;
    border: none;
    padding: 0px 14px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: var(--button-primary-background);
    font-size: 14px;
    font-weight: bold;
    color: #000;

  }
}

.close-btn {
  background: none;
  color: white;
  border: 1px;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  margin-top: -28px;
  margin-right: -28px;
}

@media only screen and (max-width: 992px) {
  .form-wrap {
    padding: 36px 24px;
  }

  .two-fields {
    flex-direction: column;

    .input-box {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .close-btn {
    margin-right: -12px;
  }
}
:root {
    /* Text Colors */
    --color-heading: #FFF;
    --color-nav: #FFF;
    --color-text: #FFF;
    --color-text-accent: #FDFFA6;
    --color-text-heading-first: rgba(255, 255, 255, 0.60);
    --color-text-code: #9E9E9E;
    --color-text-label: rgba(0, 0, 0, 0.96);

    /* Background colors */
    --background-header: #000000;
    --background-main: linear-gradient(180deg,#000,transparent 55.06%),radial-gradient(133.49% 108.58% at 8.4% 41.7%,rgba(15,84,188,0) 0,#1542ab 33.5%,#2a7bc7 50%,rgba(31,215,255,.85) 67%,#d8f49d 80%,#fdffa6 100%),#000;
    --background-main-revert: linear-gradient(0deg,#000,transparent 55.06%),radial-gradient(133.49% 108.58% at 8.4% 41.7%,rgba(15,84,188,0) 0,#1542ab 33.5%,#2a7bc7 50%,rgba(31,215,255,.85) 67%,#d8f49d 80%,#fdffa6 100%),#000;
    --background-hide: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
    --background-main-second: linear-gradient(180deg,#000,transparent 70.06%),radial-gradient(340.49% 145.58% at 189.4% 41.7%,rgba(15,84,188,1) 0%,#1542ab 33.5%,#2a7bc7 50%,rgba(31,215,255,.85) 58%,#d8f49d 65%,#fdffa6 100%),#000;
    --background-secondary: #000000;
    --background-footer: linear-gradient(180deg, rgba(21, 65, 165, 0.00) 0%, rgba(21, 65, 165, 0.34) 100%);

    /* Fonts */
    --font-family-base: "Inter";
    --font-family-heading: "Unbounded";
    --font-family-code: "JetBrains Mono";
    --font-size-nav: 14px;
    --font-size-heading: 64px;
    --font-size-heading-small: 36px;
    --font-size-text-big: 18px;
    --font-size-button-primary: 14px;
    --font-size-button-secondary: 14px;
    --font-size-button-big: 18px;
    --font-size-subheading: 12px;
    --font-size-small-title: 20px;
    --font-size-text-base: 14px;
    --font-size-text-medium: 16px;

    /* Buttons */
    --button-primary-border-color: rgba(255, 255, 255, 0.48);
    --button-primary-background: linear-gradient(180deg, #FDFFA6 0%, #C2F827 100%);
    --button-primary-box-shadow: 0px 0px 16px 4px rgba(255, 255, 255, 0.36) inset;
    --button-primary-border-radius: 100px;
    --button-primary-text-color: #000000;
    --button-secondary-text-color: #000000;

    /* Labels */
    --label-in-progress-color: #D8F49D;
    --label-finishing-color: #FDFFA6;
    --label-closed-color: #DA0D0D;

    /* Input */
    --input-background: rgba(0, 0, 0, 0.72);
    --input-border-color: rgba(255, 255, 255, 0.16);
    --input-border-radius: 12px;

    /* Blocks */
    --block-border-radius: 12px;

    /* Container */
    --page-width: 1350px;
    --container-padding: 15px;
    --transition-time: .4s;
}
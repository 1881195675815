
:root {
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-neutral-700: hsl(236, 21%, 26%);
  --clr-neutral-750: hsl(240, 22%, 21%);
  --clr-neutral-800: hsl(235, 16%, 14%);
  --clr-neutral-900: hsl(234, 17%, 12%);

  --clr-primary-500: hsl(237, 18%, 59%);
  --clr-primary-400: hsl(61, 100%, 83%);

  --fs-400: .95rem;
  --fs-600: 1.5rem;
  --fs-800: 2.3rem;

  --fw-400: 500;
  --fw-600: 600;
  --fw-700: 700;

  --card-height: 70px;
  --edge-size: 13px;
  --border: .3rem;
}


.container-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-bottom: 48px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 0;
  }
  //margin-bottom: 15rem;
}

.container-sub {
  position: relative;
  min-height: var(--card-height);
}

.container-sub::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: var(--clr-neutral-900);
  border-radius: .5rem;
  left: 0;
  top: 7px;
  z-index: -1;
}


.span {
  position: absolute;
  width: 100%;
  height: var(--edge-size);
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  overflow: hidden;
}

.span span {
  position: absolute;
  width: var(--edge-size);
  height: var(--edge-size);
  background-color: var(--clr-neutral-900);
  border-radius: 50%;
}

.span span:first-child {
  left: calc(var(--edge-size) / -2);
}

.span span:last-child {
  right: calc(var(--edge-size) / -2);
}

.clock {
  color: var(--clr-primary-400);
  font-weight: var(--fw-700);
  font-size: var(--fs-800);
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 200px;
  user-select: none;
}

.clock::after,
.clock::before {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  line-height: 0;
  position: absolute;
  overflow: hidden;
  background-color: var(--clr-neutral-700);
}

.clock::before {
  content: attr(data-before);
  bottom: 0;
  align-items: flex-start;
  border-bottom-left-radius: var(--border);
  border-bottom-right-radius: var(--border);
}

.clock::after {
  content: attr(data-after);
  top: 0;
  align-items: flex-end;
  border-top-left-radius: var(--border);
  border-top-right-radius: var(--border);
  filter: brightness(85%);
  border-bottom: 1px solid black;
}

.flip .card {
  animation: flip .6s;
}

@keyframes flip {
  50%, 100% {
    transform: rotateX(-180deg);
  }

  80% {
    animation-timing-function: ease-out;
    transform: rotateX(-170deg);
  }
}

.card {
  position: relative;
  width: 100%;
  height: 50%;
  z-index: 10;
  transform-origin: bottom;
  transform-style: preserve-3d;
}

.front,
.back {
  width: 100%;
  height: 100%;
  line-height: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  overflow: hidden;
  backface-visibility: hidden;
  background-color: var(--clr-neutral-700);
}

.front {
  align-items: flex-end;
  border-top-left-radius: var(--border);
  border-top-right-radius: var(--border);
  border-bottom: 1px solid black;
  filter: brightness(85%);
}

.back {
  transform: rotateX(180deg);
  border-bottom-left-radius: var(--border);
  border-bottom-right-radius: var(--border);
}

.front::before,
.front::after,
.back::before,
.back::after {
  content: '';
  width: var(--edge-size);
  height: var(--edge-size);
  background: var(--clr-neutral-900);
  position: absolute;
  border-radius: 50%;
}

.front::before {
  bottom: 0;
  left: 0;
  transform: translate(-50%, 50%);
}

.front::after {
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.back::before {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.back::after {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}


@media (min-width: 800px) {
  :root {
    --fs-800: 4.5rem;

    --card-height: 130px;
    --border: .6rem;
  }

  .hills img {
    width: 100%;
  }



  .container-grid {
    grid-gap: 2rem;
  }

  .container-sub::before {
    top: 12px;
    border-radius: 1.5rem;
  }

  .container-sub::after {
    font-size: .9rem;
    bottom: -40px;
  }
}
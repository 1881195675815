

.header-area {
    background-color: var(--background-header);
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    left: 0;

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .logo-area {
        display: flex;
        align-items: flex-end;
        column-gap: 12px;

        .logo {
            height: 24px;
        }

        .slogan-code {
            color: var(--color-text-code);
            font-family: var(--font-family-code);
            font-size: 12px;
            font-style: italic;
            font-weight: 500;
            line-height: 13px;
            letter-spacing: -0.48px;
            max-width: 82px;
        }
    }

    .header-navigation {
        .main-navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            @media only screen and (max-width: 992px) {
                background: rgba(0, 0, 0, 0.92);
                backdrop-filter: blur(9px);
                -webkit-backdrop-filter: blur(9px);
                flex-direction: column;
                gap: 0;
                height: calc(100vh - 80px);
                justify-content: center;
                left: -100%;
                position: fixed;
                top: 80px;
                transition: var(--transition-time);
                width: 100vw;

                &.open {
                    left: 0;
                }
            }

            @media only screen and (max-width: 576px) {
                height: calc(100vh - 57px);
                top: 57px;
            }

            .webinar-tab {
                position: relative;
                color: white;
                display: flex;
                align-items: center;
                padding: 5px 20px;
                border: 2px solid #f1fda2;
                border-radius: var(--button-primary-border-radius);
                opacity: 1;
                @media only screen and (max-width: 576px) {
                    align-self: flex-start;
                    font-size: 40px;
                    text-align: center;
                    width: auto;
                    margin: 0.2em 0 0 .9em;
                }

                &:hover, &.active {
                    background: #f1fda2;
                    color: black;
                }
            }

            .link {
                color: var(--color-nav);
                font-family: var(--font-family-base);
                font-size: var(--font-size-nav);
                font-style: normal;
                font-weight: 400;
                line-height: 180%;
                letter-spacing: -0.28px;
                opacity: 0.8;
                text-decoration: none;


                @media only screen and (max-width: 992px) {
                    color: var(--color-text);
                    font-size: 40px;
                    opacity: 1;
                    padding: 0.2em 0 0 1.5em;
                    width: 100%;

                }

                &.active {
                    color: var(--color-text-accent);
                }


            }


        }
    }
}

.burger-menu {
    display: none;
    flex-direction: column;
    gap: 5px;

    @media only screen and (max-width: 992px) {
        display: flex;
        margin-right: 12px;
        margin-bottom: 3px;
    }

    &.active {
        .first {
            transform: translate(0, 7px) rotate(-45deg);
        }

        .second {
            opacity: 0;
        }

        .third {
            transform: translate(0, -7px) rotate(45deg);
        }
    }

    .line {
        width: 22px;
        height: 2px;
        background-color: var(--color-text);
        transition: var(--transition-time);
    }
}

@media only screen and (max-width: 992px) {
    .header-area {
        width: 100vw;
    }
}


@media only screen and (max-width: 576px) {
    .header-call-to-action {
        display: none;
    }
}
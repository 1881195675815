.section-courses-list , .articles-section  {
    background-color: var(--background-secondary);
    padding: 60px 0;

    .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 36px;

        .button {
            padding-top: 8px;
            padding-bottom: 6px;
        }
    }

    .banner-title {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;

        .grey {
            display: inline;
        }
    }
}

.courses-list-list {
    display: flex;
    column-gap: 20px;

    &.flex-wrap{
        flex-wrap: wrap;
    }

    .courses-list-item {
        width: 24%;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.16);
        background: #0C0C0C;
        backdrop-filter: blur(18px);
        -webkit-backdrop-filter: blur(18px);
        position: relative;
        display: block;
        overflow: hidden;

        img {
            transition: all .2s;
        }


        &:hover {
            img {
                transform: scale(1.5);
            }

        }

        .absolute-url {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
        }

        &.courses-list-item-big {
            width: 52%;
        }

        .course-image {
            height: 180px;
            overflow: hidden;

            .course-img {
                max-width: 100%;
                height: 100%;
                width: 100%;
                object-fit: cover;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
        }

        .label {
            color: var(--color-text-label);
            font-family: var(--font-family-heading);
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 180%;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            border-radius: 4px;
            background: var(--label-in-progress-color);
            padding: 4px 8px;
            width: fit-content;
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 2;

            &.label-finishing {
                background: var(--label-finishing-color);
            }

            &.label-closed {
                background: var(--label-closed-color);
                color: var(--color-text);
            }
        }

        .corner-label {
            position: absolute;
            padding: 0;
            border: none;
            margin: 0;
            background: transparent;
            border-right: 63px solid transparent;
            line-height: 16px;
            width: 0;
            height: 0;
            top: 0;

            svg {
                width: 25px;
                height: 25px;
                position: relative;
                top: -52px;
                left: 4px;
                display: block;
            }

            &.corner-label-sale {
                color: var(--color-text);
                border-top: 57px solid var(--label-closed-color);
                z-index: 1;
            }
        }

        .course-content {
            padding: 16px 24px 24px;

            .course-type {
                color: var(--color-text);
                opacity: 0.6;
                font-size: 12px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
                margin-bottom: 6px;
            }

            .course-title {
                color: var(--color-text);
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.4px;
                margin-bottom: 12px;
                display: block;
                text-decoration: none;
            }

            .course-description {
                color: var(--color-text);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                letter-spacing: -0.24px;
                opacity: 0.8;
                margin-bottom: 16px;
            }

            .course-additional-info {
                padding-top: 16px;
                border-top: 1px solid rgba(255, 255, 255, 0.12);
                display: flex;
                flex-wrap: wrap;
                column-gap: 10px;
                row-gap: 24px;

                .course-additional-info-item {
                    width: calc(50% - 5px);
                    color: var(--color-text);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    letter-spacing: -0.28px;

                    .info-title {
                        display: block;
                        margin-bottom: 4px;
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: -0.24px;
                        opacity: 0.6;
                    }
                }
            }
        }

        &.courses-list-item-big {
            position: relative;
            display: flex;
            align-items: flex-end;
            min-height: 481px;

            .course-image {
                height: 100%;
                position: absolute;
                width: 100%;
                z-index: 0;
                
                .course-img {
                    border-radius: 12px;
                }
            }

            .course-content {
                width: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.36);
                backdrop-filter: blur(18px);
                -webkit-backdrop-filter: blur(18px);
                border-bottom-right-radius: 12px;
                border-bottom-left-radius: 12px; 
            }

            @media only screen and (min-width: 576.2px) {
                .course-content {
                    display: flex;
                    align-items: center;

                    .course-info {
                        padding-right: 24px;
                        width: 50%;
                        border-right: 1px solid rgba(255, 255, 255, 0.12);
                    }

                    .course-additional-info {
                        padding-left: 24px;
                        width: 50%;
                        border-top: 0;
                        margin-top: 0;
                        padding-top: 0;
                        align-items: center;
                    }
                }
            }
        }

        &.status-closed {
            .course-image {
                filter: grayscale(1);
            }

            .label {
                filter: grayscale(0.5);
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .courses-list-list {
        row-gap: 20px;
        flex-wrap: wrap;

        .courses-list-item {
            width: calc(50% - 10px);
            order: 0;

            &.courses-list-item-big {
                width: 100%;
                order: 1;
            }
        }
    }

    .articles-section {
        .articles-wrap {
            .article-item {
                width: calc(50% - 10px);
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .courses-list-list {
        .courses-list-item {
            width: 100%;
            order: 0;
        }
    }

    .section-courses-list  {
        .section-header {
            flex-direction: column;
            align-items: flex-start;
            row-gap: 36px;

            .section-call-to-action {
                width: 100%;

                .button {
                    width: 100%;
                    padding: 11px;
                }
            }
        }
    }

    .articles-section {
        .articles-wrap {
            .article-item {
                width: 100%;
            }
        }
    }
}
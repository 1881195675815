body {
    margin: 0;
    font-family: var(--font-family-base);
    //text-rendering: optimizeLegibility;
    //-webkit-font-smoothing: antialiased;
}

* {
    box-sizing: border-box;
}

.main-wrapper,
.page-content {
    padding-top: 80px;
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

h2 {
    color: var(--color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--font-size-heading);
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 0;

    .grey {
        color: var(--color-text-heading-first);
        display: block;
    }
}

h3 {
    color: var(--color-heading);
    font-family: var(--font-family-heading);
    font-size: var(--font-size-heading-small);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin: 0;

    .grey {
        color: var(--color-text-heading-first);
        display: block;
    }
}

h4 {
    color: var(--color-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.48px;
    line-height: 120%;
    margin: 0;

    .accent {
        color: #fdffa6;
    }

    .block {
        display: block;
    }
}

h5 {
    margin: 0;
}

.accent {
    color: #fdffa6;
}

@media only screen and (max-width: 576px) {
    h2 {
        font-size: 48px;
    }

    h3 {
        font-size: 26px;
    }
}

.section-header {
    margin-bottom: 36px;

    .banner-title {
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        text-align: center;

        span, .grey {
            display: block;

            &.inline {
                display: inline;
            }
        }
    }

    &.mb-0 {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 576px) {
    .section-header {
        .banner-title {
            &.sm-banner-small {
                font-size: 26px;
            }

            &.sm-banner-left {
                text-align: left;
            }
        }
    }
}

.main-background {
    background: var(--background-main);
}

.container {
    max-width: var(--page-width);
    margin: auto;
    padding: 0 var(--container-padding);
    position: relative;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .container {
        padding: 0 48px;
    }
}

@media only screen and (max-width: 576px) {
    .container {
        padding: 0 16px;
    }

    .main-wrapper,
    .page-content {
        padding-top: 57px;
    }
}

@keyframes buttonClick {
    0% {
        transform: scale(1);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%), linear-gradient(180deg, #FDFFA6 49.5%, #C2F827 100%);
        box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.36) inset;
    }
    50% {
        transform: scale(0.9);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.24) 0%, rgba(0, 0, 0, 0.24) 100%), linear-gradient(180deg, #FDFFA6 49.5%, #C2F827 100%);
        box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 0.36) inset;
    }
    100% {
        transform: scale(1);
        background: var(--button-primary-background);
        box-shadow: var(--button-primary-box-shadow);
    }
}

.button {
    text-decoration: none;
    width: fit-content;
    cursor: pointer;

    &.w-100{
        width: 100%;
    }

    &.button-primary {
        color: var(--button-primary-text-color);
        font-family: var(--font-family-base);
        font-size: var(--font-size-button-primary);
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        text-transform: uppercase;
        display: flex;
        padding: 7px 23px;
        margin: 4px;
        justify-content: center;
        align-items: center;
        border-radius: var(--button-primary-border-radius);
        border: 1px solid var(--button-primary-border-color);
        background: var(--button-primary-background);
        box-shadow: var(--button-primary-box-shadow);
        transform: scale(1);

        &:hover {
            background: linear-gradient(180deg, #FDFFA6 49.5%, #C2F827 100%);
        }

        &:focus {
            animation-name: buttonClick;
            animation-duration: 0.25s;
        }
    }

    &.button-primary-border {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            left: -6px;
            top: -6px;
            border-radius: var(--button-primary-border-radius);
            border: 1px solid rgba(255, 255, 255, 0.24);
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.16) 100%);
        }
    }

    &.button-big {
        font-size: var(--font-size-button-big);
        line-height: 32px;
        padding: 19px 35px;
    }

    &.button-very-big {
        padding-left: 100px;
        padding-right: 100px;

        @media only screen and (max-width: 992px) {
            padding: 19px 35px;
            width: 100%;
        }
    }

    &.button-secondary {
        margin-top: 40px;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        border: 1px solid var(--label-in-progress-color);
        color: var(--color-text);
        font-family: var(--font-family-base);
        font-size: var(--font-size-button-primary);
        border-radius: var(--button-primary-border-radius);
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        text-transform: uppercase;
        padding: 7px 23px;
        margin: 4px;
        margin-left: 50%;
        transform: translateX(-50%);
        transition: all .1s ease-out;

        &:hover {
            color: var(--color-text-label);
            background: var(--label-in-progress-color);
        }
    }

    &.margin-0 {
        margin: 0;
    }

    &.margin-auto {
        margin: auto;
    }
}

.hidden {
    display: none !important;
}

p {
    margin: 0;
}

.autowrite-text {
    &.inactive {
        color: transparent;
    }
}

.max-w-95 {
    max-width: 95%;
}

.iti__country-name {
    color: var(--color-text);
}

.iti__selected-dial-code {
    color: var(--color-text);
}

.first-screen-section {
    padding-top: 72px;
}

@media only screen and (max-width: 992px) {
    .first-screen-section {
        padding-top: 60px;
    }
}


.mobile-show {
    display: none !important;
    width: 100%;
    margin-top: 40px !important;
    text-align: center;
}

@media only screen and (max-width: 576px) {
    .mobile-hide {
        display: none;
    }
    .mobile-show {
        display: block !important;
    }

}

@media only screen and (max-width: 375px) {
    .sm {
        .sm-hidden {
            display: none;
        }
    }
}


.text-center {
    text-align: center;
}

.font-size-24 {
    font-size: 24px;
}

code, kbd {
    padding: 5px;
    border-radius: 5px;
    font-size: 0.85rem;
    background: rgba(255, 255, 255, 0.25);
    border: 1px solid hsla(0, 0%, 100%, .16);
    color: #fdffa6;
    //margin: 7px 0px;
}


.gradient-border {
    font-size: 2.5rem;
    --borderWidth: 2px;
    position: relative;
    border-radius: var(--block-border-radius);
}

.gradient-border {
    &:hover:after {
        content: '';
        position: absolute;
        top: calc(-1 * var(--borderWidth));
        left: calc(-1 * var(--borderWidth));
        height: calc(100% + var(--borderWidth) * 2);
        width: calc(100% + var(--borderWidth) * 2);
        background: linear-gradient(160deg, #fffc00, #000bff, #fffc00, #000bff, #fffc00, #000bff, #fffc00, #000bff);
        border-radius: var(--block-border-radius);
        z-index: -1;
        animation: animatedgradient 3s linear alternate infinite;
        background-size: 300% 300%;
    }
}


.play-video {
    position: absolute;
    width: 100%;
    height: calc(100% - 4px);
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%);

    .button-video {
        padding: 15px 23px;
        color: var(--button-secondary-text-color);
        font-size: var(--font-size-button-secondary);
        font-style: normal;
        font-weight: 800;
        line-height: 24px;
        text-transform: uppercase;
        border-radius: 48px;
        border: 1px solid rgba(255, 255, 255, 0.48);
        background: linear-gradient(180deg, #FFF 0%, #D8ECFF 100%);
        box-shadow: 0px 0px 16px 4px rgba(255, 255, 255, 0.36) inset;
        display: flex;
        align-items: center;
        column-gap: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%) scale(1);
        min-width: 165px;
        text-wrap: nowrap;
        transition: all 0.15s linear;

        svg {
            width: 24px;
            height: 24px;
        }

        &:hover {
            transform: translate(-50%) scale(1.1);
        }
    }
}


.m-0 {
    margin: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.my-tg {
    position: fixed;
    bottom: 20px;
    left: 30px;
    transform: scale(1);
    animation: pulse 2s infinite;

    img {
        max-width: 100%;
    }

    .tooltip {
        background: rgba(0, 0, 0, .72);
        color: #fff;
        display: none;
        left: -25%;
        padding: 5px;
        position: absolute;
        top: -25px;
        width: 150%;
        text-align: center;
        border-radius: 100px;
    }

    &:hover {
        .tooltip {
            display: block;
        }
    }

    @media only screen and (max-width: 992px) {
        max-width: 70px;
        left: 10px;
    }
}


@keyframes pulse {
    0% {
        transform: scale(0.85);
    }

    70% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.85);
    }
}

.mb-20 {
    margin-bottom: 20px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-60 {
    margin-bottom: 60px;
}

.mb-72 {
    margin-bottom: 72px !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-120 {
    margin-bottom: 120px !important;

    @media only screen and (max-width: 992px) {
        margin-bottom: 32px !important;
    }
}

.fw-bold {
    font-weight: bold !important;
}

.font-size-110-mobile {
    @media only screen and (max-width: 992px) {
        font-size: 110px !important;
    }
}
//
//@media only screen and (max-width: 992px) {
//    .mobile-show {
//        display: block;
//    }
//    .mobile-hide{
//        display: none;
//    }
//}
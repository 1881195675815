.maraphon-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .new-price-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }

    .item {
      width: 33%;
      @media only screen and (max-width: 992px) {
        width: 100%;
        &.order-3 {
          order: 3;
        }
        //  top: 0;
      }

      &.center-item {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -85px;
        @media only screen and (max-width: 992px) {
          top: 0;
        }
      }

      .icon {
        img {
          width: 420px;
          max-width: 100%;
        }

      }
    }

    .icon-text {
      color: white;
      margin-bottom: -38px;
      font-size: 24px;
      @media only screen and (max-width: 992px) {
        font-size: 18px;
      }
    }
  }

  .price {
    color: var(--color-text-accent);
    font-family: var(--font-family-heading);
    font-size: 140px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -9.6px;
    line-height: normal;
    text-align: center;
    //margin-right: 10px;
    width: 100%;

    &.old {
      //font-size: 64px;
      //// text-decoration: line-through;
      //position: relative;
      //width: fit-content;
      //height: fit-content;
      //
      ////new style
      //letter-spacing: -5.6px;
      //font-size: 48px;
      //color: #da0d0d;
      //position: relative;
      //top: 10px;
      //

      // new styles
      color: white;
      font-size: 64px;
      font-size: 48px;
      height: fit-content;
      letter-spacing: -1.6px;
      position: relative;
      top: 10px;
      width: fit-content;
      //background: #da0d0d;
      padding: 10px 16px 10px 10px;
      border-radius: 70px;
      font-weight: 500;

      @media only screen and (max-width: 576px) {
        text-align: center;
        margin: auto;
        margin-top: 0 !important;
      }


      .brightness {
        //filter: brightness(0.7);
      }

      // text-decoration-color: #da0d0d;
      // color: #da0d0d;
      &:after {
        background: #ff0200;
        border-radius: 5px;
        content: "";
        height: 5px;
        left: 0;
        position: absolute;
        top: 48%;
        transform: rotate(-16deg) translate(0);
        width: calc(100% + 4px);
      }
    }

    &.new {
      font-weight: 500;
      //letter-spacing: -16px;
      padding-right: 20px;
      margin-top: -10px;
    }
  }

  .icons-wrap {

    .icon-text {

    }
  }
}


.maraphon {

  .font-size-small-phone {
    @media only screen and (max-width: 576px) {
      font-size: 14px;
      width: 100%;
    }
  }

  .timer {
    margin-top: 12px;
    font-size: 32px;
  }

  .timer-down-wrap {
    max-width: 440px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .main-background {
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 992px) {
    .text-with-image {
      margin-top: 0;
    }
  }

  .text-with-image-title {
    margin-bottom: 32px;
    margin-top: 84px;
  }

  .help-text {
    color: white;
    font-size: 22px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .special-block {
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    background: rgba(0, 0, 0, .72);
    border: 1px solid hsla(0, 0%, 100%, .16);
    border-radius: 36px;
    box-shadow: inset 0 0 48px 0 hsla(0, 0%, 100%, .12);
    box-sizing: border-box;
    color: var(--color-text);
    min-width: 180px;

    padding: 15px 24px;


  }

  .special-block-wrap {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    flex-wrap: wrap;

    .special-item {
      display: flex;
      flex-direction: column;
      min-height: 400px;
      position: relative;

      .number {
        border: 1px solid #faedc1;
        border-radius: 50%;
        padding: 5px;
        position: absolute;
        left: -15px;
        top: -15px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .extra-text {
        font-size: 18px;
        width: 60%;
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }

    .w-24p {
      width: calc(50% - 12px);
      // background: red;
      height: 150px;
      padding: 30px;
      display: flex;
      // align-items: center;
      font-size: 28px;
      flex-direction: column;
      justify-content: space-between;
      background-size: cover;
      position: relative;
      border: none;
      overflow: hidden;

      @media only screen and (max-width: 992px) {
        width: 100%;
        padding: 22px;
      }

      &:after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        z-index: -1;

      }
    }

  }

  .profit-student-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    .w-25p {
      width: calc(25% - 18px);
      height: 360px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      background-size: cover;
      position: relative;
      overflow: hidden;
    border: none;
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.65);
        position: absolute;
        z-index: -1;

      }

      @media only screen and (max-width: 992px) {
        width: 100%;
      }

      .main-text {
        font-size: 28px;
      }

      .help-text {
        font-size: 14px;
        text-align: left;
      }
    }
  }

  .motivation-text {
    margin: auto;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }


  .countries-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .col {
      width: 100%;
    }

    .country-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;


      img {
        max-width: 50px;
        margin-right: 15px;
        margin-left: 15px;
      }

      h4 {
        color: white;
        font-size: 38px;
      }

    }

    .images-wrap {
      img {
        max-width: 100%;
        border-radius: 12px;
      }

      .exp-text {
        color: white;
        margin-bottom: 16px;
        margin-top: 32px;
        font-size: 32px;
        text-align: center;
      }
    }
  }

  .program-wrap {
    display: flex;
    flex-direction: column;
    gap: 24px;


    .program-item {
      cursor: pointer;

      &:hover, &.open {
        background: rgba(0, 0, 0, .85);
        border: 1px solid #fce6a0;
      }

      .triangle {
        display: flex;
        transition: transform 500ms;
      }

      &.open {
        .triangle {
          transform: rotate(180deg);
        }

      }

      .item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 150px;
        flex-wrap: wrap;

        @media only screen and (max-width: 992px) {
          flex-wrap: wrap;
          height: fit-content;
        }

      }

    }

    .module-name {
      font-size: 2.4rem;
      line-height: 1.25;
      width: 20%;

      &.center-module-name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: -80px;

        @media only screen and (max-width: 576px) {
          flex-direction: row;
          margin-bottom: 0;
          margin-top: 12px;

        }

        .count {
          font-size: 120px;
          font-weight: bold;
          @media only screen and (max-width: 576px) {
            font-size: inherit;
            margin-right: 8px;
          }
        }
      }

      @media only screen and (max-width: 992px) {
        width: 100%;
        font-size: 1.7rem;
        margin-bottom: 8px;
      }

      img {
        width: 45px;
      }
    }

    .title {
      margin-right: 15px;
      font-size: 2.4rem;
      line-height: 1.25;
      width: 58%;
      @media only screen and (max-width: 992px) {
        width: 100%;
        margin-right: 0;
        font-size: 1.35rem;
      }
    }

    .show-hide {
      width: 20%;
      text-align: center;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      @media only screen and (max-width: 992px) {
        display: none;
      }
    }

    .detail-block-wrap {
      width: 100%;
      display: flex;
      max-height: 0;
      transition: max-height 500ms;
      overflow: hidden;


      @media only screen and (max-width: 992px) {
        flex-wrap: wrap;
      }

      .title {
        width: 75%;
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }

      &.active {
        max-height: 1000px;
        @media only screen and (max-width: 992px) {
          max-height: 2000px;
          //display: flex;

        }
      }

      .detail {
        display: flex;
        width: 80%;
        background: red;
      }

      .result-text {
        background: linear-gradient(51deg, #d1b069 0%, #faedc1 25.5%, #ffe188 47.5%, #faedc1 68.5%, #997d50 130%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2.4rem;
        display: flex;
        align-items: center;

        @media only screen and (max-width: 992px) {
          font-size: 2rem;
          margin-top: 12px;
        }

        img {
          width: 50px;
          margin-right: 10px;

          @media only screen and (max-width: 992px) {
            width: 45px;
          }
        }
      }

      .result-list {
        margin-top: 10px;

        li {
          font-size: 26px;
          margin-bottom: 8px;
          @media only screen and (max-width: 992px) {
            font-size: 16px;
          }
        }
      }
    }

  }
}

.gold-text {
  background: linear-gradient(51deg, #d1b069 0%, #faedc1 25.5%, #ffe188 47.5%, #faedc1 68.5%, #997d50 130%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &.center {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}

.salary-title {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    img {
      display: none;
    }
  }

  span {
    margin: 0 10px;
  }

}

.bold {
  font-weight: bold;
}


.diagram-wrap {
  display: flex;
  flex-direction: column-reverse;
  //gap: 24px;
  //height: 60vh;
  //align-items: end;


  .diagram-item {
    display: block;
    //background: red;
    background: linear-gradient(51deg, #d1b069, #faedc1 25.5%, #ffe188 47.5%, #faedc1 68.5%, #997d50 130%);
    margin-bottom: 20px;
    height: 40px;
    width: 0;
    padding: 10px;
    transition: width 500ms ease-in-out;
    border-radius: 36px;
    @media only screen and (max-width: 576px) {
      height: 30px;
      padding: 8px;
    }


    .diagram-text-wrap {
      display: flex;
      //flex-direction: column;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .diagram-salary {
        color: #0e2e7d;
        font-size: 20px;
      }

      @media only screen and (max-width: 576px) {
        font-size: 10px;
        .diagram-salary {
          font-size: 12px;

          &.duration-text {
            font-size: 8px;
          }
        }
      }
    }
  }
}

.template-courses {
    .courses-list-list {
        gap: 20px;

        .courses-list-item {
            width: calc(33.33% - 16px);
        }

        @media only screen and (max-width: 992px) {
            .courses-list-item {
              width: calc(50% - 10px);
            }
          }
        
        @media only screen and (max-width: 576px) {
            .courses-list-item {
              width: 100%;
            }
        }
    }
}

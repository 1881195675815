.article-section, .privacy-section {
  //background: #081323;
  background: black;
  padding-top: 30px;

  .article-flex {
    display: flex;
    justify-content: space-between;
  }

  .read-more-title {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    line-height: 1.3;
    margin-bottom: 16px;
    color: var(--color-text);
    font-family: var(--font-family-base);
    font-weight: bold;

    .grey {
      display: inline;
    }
  }

  .article-wrap , .privacy-wrap {
    padding: 48px;
    //border-radius: 36px;
    width: 70%;
    border-radius: var(--block-border-radius);
    box-shadow: inset 0 0 48px 0 hsla(0, 0%, 100%, .12);
    border: 1px solid hsla(0, 0%, 100%, .16);

    * {
      color: var(--color-text);
    }

    .author-wrap {
      margin-bottom: 16px;
      display: flex;
      gap: 16px;
      justify-content: space-between;

      * {
        font-size: .8125rem;
      }

      .date {
        opacity: 0.6;
      }

      .name {
        color: #d8f49d;
      }
    }

    .external-info {
      font-size: .8125rem;
      line-height: 1.5rem;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: 12px;
      opacity: .8;

      .icon-item {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .icon {
        display: flex;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .short-desc {
      font-size: 14px;
      margin-top: 16px;
      opacity: .8;
    }

    .image {
      margin-bottom: 12px;
    }

    .title-wrap {
      margin-top: 16px;
      margin-bottom: 16px;

      .article-header {
        font-family: var(--font-family-heading);
        font-size: 2.25em;
        line-height: 120%;
      }

      img {
        max-width: 100%;
        width: 100%;
        border-radius: 8px;
      }
    }

    .content {

      code, kbd {
        max-width: 100%;
        overflow-x: auto;
        margin: 8px 0px;

        @media only screen and (max-width: 576px) {
          font-size: 12px !important;

        }
      }

      code {
        display: block;
      }

      *:not(h1,h2,h3,h4,h5,h6) {
        color: var(--color-text);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -.32px;
        line-height: 180%;
        opacity: 0.92;

        @media only screen and (max-width: 576px) {
          line-height: 22px;
          font-size: 16px;
          letter-spacing: -0.12px;

        }
      }

      img {
        max-width: 100%;
        margin: 8px 0px;
        height: auto !important;
      }

      h1, h2, h3, h4, h5, h6 {
        color: var(--color-text);
        font-size: 32px;
        margin: 16px 0px;
      }
    }
  }

  .privacy-wrap {
    margin: auto;
    margin-bottom: 60px;

    .content {
      h1, h2, h3, h4, h5, h6 {
        font-size: 24px;
      }
    }
  }

  .info-block {
    width: 25%;

    .banner-title {
      margin-bottom: 16px;

    }

    .article-item {
      margin-bottom: 16px;
      border: 1px solid hsla(0, 0%, 100%, .16);
    }
  }
}

@media only screen and (max-width: 992px) {
  .article-section, .privacy-section {
    .article-flex {
      flex-direction: column;
    }

    .article-wrap, .privacy-wrap {
      width: 100%;
      padding: 24px;
    }

    .info-block {
      width: 100%;
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .read-more-title {
        margin-bottom: 16px;
        font-size: 36px;
        width: 100%;
      }

      .article-item {
        width: calc(50% - 10px);
        margin: 0;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .article-section, .privacy-section {
    .article-wrap, .privacy-wrap {
      padding: 24px 14px;

      .title-wrap {
        .article-header {
          font-size: 1.5em;
        }
      }
    }

    .info-block {
      width: 100%;
      margin-top: 30px;

      .read-more-title {
        margin-bottom: 0;
      }

      .article-item {
        width: 100%;
      }
    }
  }
}

.banner {
    display: flex;
    justify-content: space-between;
    min-height: 703px;
    align-items: center;
    width: 100%;
    column-gap: 30px;

    &.height-auto {
        min-height: auto;
    }

    .banner-header {
        max-width: 739px;
        width: 65%;
        z-index: 1;

        .banner-text {
            margin: 24px 0 0;
            color: var(--color-text);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.36px;
            opacity: 0.8;
        }

        .banner-call-to-action {
            margin: 48px 0 0;
        }
    }

    .banner-courses {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        margin-right: 60px;
        padding: 34px 0;
        width: 35%;
        position: relative;
        z-index: 0;

        .absolute-url{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
        }

        .banner-image {
            width: 240px;
            height: 240px;
            position: absolute;
            top: 0;
            left: -50%;
            z-index: 0;
        }

        .banner-course {
            border-radius: var(--block-border-radius);
            border: 1px solid rgba(255, 255, 255, 0.16);
            background: rgba(0, 0, 0, 0.72);
            box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.12) inset;
            //backdrop-filter: blur(18px);
            //-webkit-backdrop-filter: blur(18px);
            margin: 8px 0;
            padding: 16px 24px;
            position: relative;

            .course-label-wrapper {
                border-radius: var(--block-border-radius);
                overflow: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                top: 0;
                left: 0;
            }

            .corner-label {
                position: absolute;
                padding: 0;
                border: none;
                margin: 0;
                background: transparent;
                border-left: 56px solid transparent;
                line-height: 16px;
                width: 0;
                height: 0;
                top: 0;
                right: 0;
    
                svg {
                    width: 20px;
                    height: 20px;
                    position: relative;
                    right: 27px;
                    top: -44px;
                    display: block;
                }
    
                &.corner-label-sale {
                    color: var(--color-text);
                    border-top: 48px solid var(--label-closed-color);
                    z-index: 1;
                }
            }

            .course-type {
                opacity: 0.6;
                color: var(--color-text);
                font-size: var(--font-size-subheading);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
                margin: 0 0 6px;
            }

            .course-name {
                color: var(--color-text);
                font-size: var(--font-size-small-title);
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: -0.4px;
                margin: 0 0 16px;
                display: block;
                text-decoration: none;
            }

            .course-footer {
                border-top: 1px solid rgba(255, 255, 255, 0.12);
                padding-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .course-start {
                opacity: 0.6;
                color: var(--color-text);
                font-size: var(--font-size-text-base);
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.28px;
                margin: 0 0;
            }

            .course-start-date {
                color: var(--color-text);
                font-size: var(--font-size-text-base);
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.28px;
            }
        }
    }

    .banner-right-image {
        width: 420px;
        height: 420px;

        .banner-image {
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            position: static;
        }
    }
}

@media only screen and (max-width: 992px) {
    .banner {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        min-height: unset;
        padding-top: 100px;
        padding-bottom: 80px;

        .banner-header {
            width: 100%;
            max-width: unset;
        }

        .banner-courses {
            max-width: unset;
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
            justify-content: center;
            padding: 36px 0;
            margin-top: 60px;

            .banner-course {
                width: calc(50% - 4px);
            }

            .banner-image {
                left: auto;
                right: 0;
                top: -137px;
            }
        }

        .banner-right-image {
            height: 240px;
            width: 240px;
            margin: -90px 0 0 auto;
        }
    }
}

@media only screen and (max-width: 576px) {
    .banner {
        padding-bottom: 24px;
        padding-top: 48px;

        .banner-header {
            .banner-call-to-action {
                .button {
                    width: calc(100% - 8px);
                    padding: 17px;
                }
            }
        }

        .banner-courses {
            margin-top: 24px;

            .banner-course {
                width: 100%;
            }

            .banner-image {
                display: none;
            }
        }

        .banner-right-image {
            display: none;
        }
    }
}

.advantages-wrap {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    &.col-2 {
        justify-content: space-between;
        .adv-option {
            width: 49%;
            @media only screen and (max-width: 576px) {
                width: 100%;
            }
        }
    }

    .adv-option {

        display: flex;
        align-items: center;
        margin-bottom: 15px;
        width: 100%;

        &:hover {
            .text p {
                color: var(--color-text-accent);
            }
        }

        .icon {
            img {
                width: 48px;
            }

        }

        .text {
            line-height: 19px;
            margin-left: 10px;

            p {
                font-size: 22px;
                font-weight: 400;
                line-height: 25px;

                margin: 5px 0;

            }

            small {
                font-size: 15px;
                font-weight: 300;
                line-height: 0px;
                opacity: .6;
            }
        }
    }

}

.text-with-image {
    border-radius: 36px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    padding: 48px;
    margin: 20px 0;

    .text-with-image-title {
        margin-bottom: 48px;

    }

    .text-with-image-content {
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: rgba(0, 0, 0, 0.72);
        box-shadow: 0px 0px 48px 0px rgba(255, 255, 255, 0.08) inset;
        backdrop-filter: blur(18px);
        -webkit-backdrop-filter: blur(18px);
        display: flex;
        padding: 8px;
        column-gap: 10px;
    }

    .text-with-image-text-info {
        width: 65%;
        padding: 36px 48px 24px;

        .text-with-image-experiences {
            display: flex;
            column-gap: 36px;
            justify-content: space-between;
            
            .text-with-image-experience {
                width: 30%;
                max-width: 203px;

                .experience-number {
                    color: var(--color-text-accent);
                    text-align: center;
                    font-family: var(--font-family-heading);
                    font-size: 96px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    letter-spacing: -5.76px;
                    margin-bottom: 4px;
                }

                .experience-description {
                    color: var(--color-text);
                    font-size: var(--font-size-text-big);
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: -0.36px;
                    text-align: center;
                    //opacity: 0.6;
                }
            }
        } 

        .text-with-image-description {
            //margin-top: 60px;
            //padding-top: 60px;
            margin-top: 30px;
            padding-top: 30px;
            color: var(--color-text);
            font-size: var(--font-size-text-medium);
            font-style: normal;
            font-weight: 400;
            line-height: 180%;
            letter-spacing: -0.32px;
            border-top: 1px solid rgba(255, 255, 255, 0.16);

            &.description-event {
                margin-top: 40px;
                padding-top: 40px;
            }




            @media only screen and (max-width: 576px) {
                margin-top: 30px;
                padding-top: 30px;
            }
        }
    }

    .text-with-image-video {
        border-radius: 8px;
        width: 35%;
        max-width: 420px;
        min-width: 300px;
        position: relative;

        .text-with-image-img {
            width: 100%;
            object-fit: cover;
            display: block;
            border-radius: 8px;
            height: 100%;
        }

        video {
            border-radius: 8px;
            max-width: 100%;
            height: 100%;
            object-fit: cover;
            width: 100%;
        }


    }
}

@media only screen and (max-width: 992px) {
    .text-with-image {
        margin-top: 60px;

        .text-with-image-content {
            flex-direction: column;

            .text-with-image-text-info {
                width: 100%;
                padding-bottom: 80px;

                .text-with-image-experiences {
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 36px;

                    .text-with-image-experience {
                        width: 40%;
                        text-align: center;

                        .experience-number {
                            text-align: center;
                        }
                    }
                }
            }

            .text-with-image-video {
                width: 100%;
                max-width: unset;
                min-width: unset;
                min-height: 400px;

                video {
                    min-height: 400px;
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .text-with-image {
        margin-top: 36px;
        padding: 0;
        border: 0;
        background: none;
        box-shadow: none;
        backdrop-filter: none;

        .text-with-image-content {
            .text-with-image-text-info {
                padding: 36px 12px;

                .text-with-image-experiences {
                    gap: 36px;

                    .text-with-image-experience {
                        width: 70%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

.text-with-image-logoes {
    max-width: 1040px;
    margin: 0 auto;
    padding: 60px 0 58px;

    h3 {
        text-align: center;
        margin-bottom: 60px;
        font-size: 31px;
    }

    .logo-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 48px;
    }

    .logo {
        svg {
            width: 240px;
        }

        img {
            width: 240px;
        }
    }

    .notes {
        color: var(--color-text);
        opacity: 0.6;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 180%;
        letter-spacing: -0.28px;
    }
}

@media only screen and (max-width: 992px) {
    .text-with-image-logoes {
        h3 {
            font-size: 26px;
            margin-bottom: 48px;
        }

        .logo-list {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 60px;
            column-gap: 180px;
        }
    }
}

.courses-list {
    padding: 60px 0;
}
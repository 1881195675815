.webinar-hello-video {
  position: relative;
  width: 80%;
  //margin: auto;
  border-radius: var(--block-border-radius);
  overflow: hidden;
}

.actual-webinar-wrap {
  border: 1px solid hsla(0, 0%, 100%, .08);
  padding: 12px 36px;
  border-radius: 16px;
}

.video-preview-webinar {
  display: flex;
  justify-content: flex-end;

}

.form-webinar-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 200px;
  //overflow: hidden;

  &.hide {
    max-height: 0;
    overflow: hidden;
  }
}

.form-title {
  width: 100%;
  margin-bottom: 16px;
}

.webinars-wrap {
  margin-top: 40px;
}


.webinar-titles {
  margin-bottom: 20px;
}


.join-tg {
  margin-top: 10px;
  &.center {
    display: flex;
    justify-content: center;
  }
}
.section-all-reviews {
  margin: -10px;
  padding-bottom: 60px;
  
  .video-item {
      height: 480px;
      border-radius: 12px;
      margin: 10px;
  
      .video-media {
        position: relative;
        width: 100%;
        height: 100%;
      }
  
      .video {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 12px;
      }
  }

  .item-review {
    width: calc(25% - 20px);
  }

  @media only screen and (max-width: 992px) {
    .item-review {
      width: calc(50% - 20px);
    }
  }

  @media only screen and (max-width: 576px) {
    .item-review {
      width: calc(100% - 20px);
    }
  }
}
.video-wrap {
  .video-item {
    width: 270px;
    margin-right: 16px;
    height: 480px;
    border-radius: 12px;

    .video-media {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .video {
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
}


.play-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.16) 0%, rgba(0, 0, 0, 0.16) 100%);
  border-radius: 12px;

  .play-img {
    width: 64px;
    height: 64px;
    display: block;
    margin: auto;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.15s linear;
    // user-select: none;
    // pointer-events: none;

    &:hover {
      transform: scale(1.1);
    }
  }
}

@media only screen and (max-width: 992px) {
  .play-video {
    .play-img {
      width: 56px;
      height: 56px;
    }
  }
}
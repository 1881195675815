.modal-wrapper {
  display: none;

  &.show {
    display: block;
    max-width: 50%;
    width: 560px;
    position: fixed;
    top: 20%;
    transform: translate(-50%);
    left: 50%;
    z-index: 11;
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    background: rgba(0, 0, 0, .72);
    border: 1px solid hsla(0, 0%, 100%, .12);
    border-radius: 12px;
    box-shadow: inset 0 0 48px 0 hsla(0, 0%, 100%, .08);
    box-sizing: border-box;
    color: var(--color-text);
    padding: 48px;
  }

}

.background-overlay {
  display: none;

  &.show {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background: #001b43;
    opacity: 0.7;
    z-index: 10;
    backdrop-filter: blur(190px);
    -webkit-backdrop-filter: blur(190px);
  }

}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 36px;

  @media only screen and (max-width: 576px) {
    margin-bottom: 12px;
    .modal-title {
      font-size: 20px;
    }
  }

  .modal-title {
    margin: 0;
  }
}

.modal-body {
  form {
    .button {
      margin-top: 40px;

      @media only screen and (max-width: 576px) {
        margin-top: 0;

      }
    }
  }

  .modal-price {
    margin-bottom: 16px;

    @media only screen and (max-width: 576px) {
      font-size: 18px;
      margin-bottom: 12px;
    }
  }

  #promo_result_modal {
    margin-bottom: 16px;
  }

  .check-promo-btn {
    cursor: pointer;
  }
}

.price-promo{
  margin-bottom: 16px;
}

@media only screen and (max-width: 992px) {
  .modal-wrapper {
    &.show {
      max-width: 90%;
    }
  }
}

@media only screen and (max-width: 576px) {
  .modal-wrapper {
    &.show {
      top: 50%;
      left: 50%;
      margin: auto;
      transform: translateY(-50%) translateX(-50%);
      padding: 36px 24px;
    }

    .box-field:not(.js-phone-number) {
      padding: 12px !important;
    }
  }
}


.promo-not-found{
  color: #e42426;
  margin-bottom: 0;
  font-size: 16px;
  margin-left: 12px;
}
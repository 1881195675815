.register-form-area {
  background: var(--background-main-second);

  &.no-background{
    background: none;
  }

  .register-form-wrap {
    display: flex;
  }
}

.form-col {
  width: 100%;
  max-width: 464px;
}

.callback-form {
  max-width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: 80vh;

  &.hide{
    max-height: 0;
  }

  .button {
    width: calc(100% - 11px);
    margin: 36px 5px 5px;
    font-weight: 800;
    line-height: 24px;
    font-size: 18px;
    padding: 23px 35px;
  }
}

.text-col {
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  row-gap: 60px;
  justify-content: center;

  .block-text-wrap {
    max-width: 240px;
    width: 100%;

    .title {
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.32px;
      margin-bottom: 8px;
    }

    .text {
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.28px;

    }
  }
}

.section-register {
  padding: 80px 0 60px;

}

@media only screen and (max-width: 992px) {
  .register-form-area {
    .register-form-wrap {
      .image-col {
        display: none;
      }
    }
  }

  .section-register {
    padding: 60px 0;
  }
}

@media only screen and (max-width: 576px) {
  .register-form-area {
    .register-form-wrap {
      .text-col{
        display: none;
      }
    }
  }
}
.articles-wrap {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.article-item {
  border-radius: 12px;
  overflow: hidden;
  width: calc(25% - 15px);
  background: #1a1a1a;

  &.w-100{
    width: 100%;
  }

  .img {
    max-width: 100%;
    width: 100%;
    transition: transform .2s;
    height: 100%;
    object-fit: cover;
  }

  .article-img {
    max-height: 180px;
    overflow: hidden;
    position: relative;

    .absolute-url {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
    }

    &:hover {
      .img {
        transform: scale(1.5)
      }
    }
  }

  .article-block {
    padding: 16px 24px 24px;

    .author-block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;

      .author {
        color: #D8F49D;
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.24px;
      }

      .date {
        font-size: 12px;
        color: var(--color-text);
        opacity: 0.6;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.24px;
      }
    }

    .desc-title {
      color: var(--color-text);


      .link {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-bottom: 12px;
        color: var(--color-text);
        text-decoration: none;
        display: block;
      }

      .desc {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        opacity: 0.8;
        letter-spacing: -0.24px;

        &.short {
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

}

.section-blog {
  padding-bottom: 60px;
}

@media only screen and (max-width: 992px) {
  .article-item {
    width: calc(50% - 10px);

    .article-img {
      max-height: 160px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .article-item {
    width: 100%;

    .article-img {
      max-height: 180px;
    }
  }
}
.footer {
    background: var(--background-footer);
    background-color: var(--background-secondary);

    .footer-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 20px;
        padding: 48px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.12);
    }

    .footer-text {
        max-width: 612px;

        .text {
            color: var(--color-text);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.28px;
            opacity: 0.8;
            margin-bottom: 16px;
        }

        .copyright {
            color: var(--color-text);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.24px;
            opacity: 0.4;
        }
    }

    .footer-link-list {
        .link-list--title {
            margin-bottom: 16px;
            color: var(--color-text);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.4px;
        }

        .link-list--list {
            display: flex;
            flex-direction: column;
            row-gap: 8px;

            .link {
                text-decoration: none;
                color: var(--color-text);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                letter-spacing: -0.28px;
                opacity: 0.8;
                display: block;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .footer-text {
            max-width: 224px;
            width: 40%;
        }
    }

    @media only screen and (max-width: 576px) {
        .container {
            padding: 0 48px;
        }
        
        .footer-content {
            flex-wrap: wrap;
            row-gap: 60px;

            .footer-text {
                max-width: unset;
                width: 100%;
            }

            .footer-link-list {
                width: 100%;
            }
        }
    }
}
@font-face{ 
	font-family: 'Inter';
    font-weight: 100;
    font-style: normal;
	src: url('Inter-Thin.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 200;
    font-style: normal;
	src: url('Inter-ExtraLight.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 300;
    font-style: normal;
	src: url('Inter-Light.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 400;
    font-style: normal;
	src: url('Inter-Regular.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 500;
    font-style: normal;
	src: url('Inter-Medium.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 600;
    font-style: normal;
	src: url('Inter-SemiBold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 700;
    font-style: normal;
	src: url('Inter-Bold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 800;
    font-style: normal;
	src: url('Inter-ExtraBold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Inter';
    font-weight: 900;
    font-style: normal;
	src: url('Inter-Black.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 200;
    font-style: normal;
	src: url('Unbounded-ExtraLight.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 300;
    font-style: normal;
	src: url('Unbounded-Light.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 400;
    font-style: normal;
	src: url('Unbounded-Regular.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 500;
    font-style: normal;
	src: url('Unbounded-Medium.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 600;
    font-style: normal;
	src: url('Unbounded-SemiBold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 700;
    font-style: normal;
	src: url('Unbounded-Bold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 800;
    font-style: normal;
	src: url('Unbounded-ExtraBold.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'Unbounded';
    font-weight: 900;
    font-style: normal;
	src: url('Unbounded-Black.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'JetBrains Mono';
    font-weight: 500;
    font-style: normal;
	src: url('JetBrainsMono-Medium.ttf') format('truetype'),
}

@font-face{ 
	font-family: 'JetBrains Mono';
    font-weight: 500;
    font-style: italic;
	src: url('JetBrainsMono-MediumItalic.ttf') format('truetype'),
}
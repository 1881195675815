@layer step2styles;

.detail {
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  background: linear-gradient(180deg, hsla(0, 0%, 100%, .04), hsla(0, 0%, 100%, .02));
  border: 1px solid hsla(0, 0%, 100%, .08);
  border-radius: 12px;
  margin: 20px 0;
  color: var(--color-text);

  transition: all .2s;

  &:hover {
    background: rgba(0, 0, 0, .72);
    backdrop-filter: blur(18px);
    box-shadow: inset 0 0 48px 0 hsla(0, 0%, 100%, .08);
  }

  .answer {
    color: var(--color-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -.36px;
    line-height: 160%;
    opacity: .8;
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    //padding: 16px 24px;

    &.close {
      display: none;
    }
  }

  .question {
    font-size: 20px;
    cursor: pointer;
    padding: 16px 24px;
  }

  .question.is-open + .answer {
    padding-top: 0;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 16px;

  }
}



